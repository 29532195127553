import type { Cart, Offer } from '~/types/Cart';

export interface CartStore {
  cart: Cart;
  mappedCartItems: { id: number; count: number }[];
  isCartLoaded: boolean;
}

export const useCartStore = createCartStore({
  cart: {
    cart_id: 0,
    status: '',
    items: [],
    calculate: {
      discounts: [],
      offer_price_and_discount: [],
      price_without_discounts: 0,
      promo_code: undefined,
      total: 0,
      totalDiscounts: {},
    },
  },
  mappedCartItems: [],
  isCartLoaded: false,
});

export function createCartStore(initialState: CartStore) {
  return defineStore('cart', {
    state: () => initialState,

    actions: {
      async getCart() {
        try {
          this.isCartLoaded = false;

          const { data } = await useCustomFetch<Cart>('/cart');

          if (data.value) {
            this.cart = data.value;
            this.mappedCartItems = this.cart.items.map((item: Offer) => ({ id: item.id, count: item.count }));

            this.isCartLoaded = true;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
        }
      },

      async addToCart(offerId: number) {
        this.mappedCartItems.push({ id: offerId, count: 1 });

        return await this.changeCartState(this.mappedCartItems);
      },

      async removeFromCart(offerId: number) {
        if (this.mappedCartItems.length) {
          if (window.r46) window.r46('track', 'remove_from_cart', offerId);

          await this.changeCartState(this.mappedCartItems.filter((i) => i.id !== offerId));
        }
      },

      async increment(offerId: number) {
        const item = this.mappedCartItems.find((i) => i.id === offerId);

        if (item) item.count++;

        await this.changeCartState(this.mappedCartItems);
      },

      async decrement(offerId: number) {
        const item = this.mappedCartItems.find((i) => i.id === offerId);

        if (item) {
          item.count--;

          if (item.count === 0) {
            await this.removeFromCart(offerId);
          } else {
            await this.changeCartState(this.mappedCartItems);
          }
        }
      },

      async changeCartState(newCartState: { id: number; count: number }[]) {
        try {
          const { data } = await useCustomFetch<Cart>('/cart', { method: 'POST', body: newCartState }, '2');

          if (data.value) {
            this.cart = data.value;
            this.mappedCartItems = this.cart.items.map((item: Offer) => ({ id: item.id, count: item.count }));

            return data.value.status;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
        }
      },

      async applyPromoCode(code: string) {
        try {
          const { data } = await useCustomFetch<Cart | any>('/promo/apply', { method: 'POST', body: { code } });

          if (data.value) {
            return data.value;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
        }

        return '';
      },

      async removePromoCode() {
        try {
          const { data } = await useCustomFetch<Cart>('/promo/delete', { method: 'DELETE' });

          if (data.value) {
            this.cart = data.value;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
        }
      },
    },
  });
}
